import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../../layouts/secondary-landing";
import HeroChevron from "../../../components/hero/hero-chevron";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import ContactInfo from "../../../components/contact-info/contact-info";
import SEO from "../../../components/seo/seo";
import TwoColumnSectionWithImage from "../../../components/custom-widgets/two-column-section-with-image";
import List from "../../../components/custom-widgets/list";
import Button from "../../../components/custom-widgets/button";
import BestBanksDefault from "../../../components/best-banks/best-banks-default";

import getHeroImgVariables from "../../../helpers/getHeroImgVariables";
import showMktoForm from "../../../helpers/showMktoForm";

const GovernmentBanking = () => {
  const title = "Government Banking Full Service Regional Bank";
  const description =
    "WaFd Bank has the experience, flexibility, and dedication it takes to offer the public sector reliable banking. Learn more and get started today.";
  const imgData = useStaticQuery(graphql`
    query {
      imgVariableXXL: file(
        relativePath: {
          eq: "hero/commercial-banking/specialized-industries/institutional-government-banking/hero-gov-inst-banking-012623-XXL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: {
          eq: "hero/commercial-banking/specialized-industries/institutional-government-banking/hero-gov-inst-banking-012623-XL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: {
          eq: "hero/commercial-banking/specialized-industries/institutional-government-banking/hero-gov-inst-banking-012623-LG.jpg"
        }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: {
          eq: "hero/commercial-banking/specialized-industries/institutional-government-banking/hero-gov-inst-banking-012623-MD.jpg"
        }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: {
          eq: "hero/commercial-banking/specialized-industries/institutional-government-banking/hero-gov-inst-banking-012623-SM.jpg"
        }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: {
          eq: "hero/commercial-banking/specialized-industries/institutional-government-banking/hero-gov-inst-banking-012623-XS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: {
          eq: "hero/commercial-banking/specialized-industries/institutional-government-banking/hero-gov-inst-banking-012623-XXS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);
  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/commercial-banking/specialized-industries/institutional-government-banking"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-gov-inst-banking-012623.jpg"
      }
    ]
  };
  const heroChevronData = {
    id: "government-banking-hero",
    ...getHeroImgVariables(imgData),
    altText: "Businesswoman standing in front of government building columns.",
    chevronBgClass: "bg-info",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Here to Help Your Mission Succeed"
          }
        },
        {
          id: 2,
          button: {
            type: "button",
            class: "btn-white",
            text: "Contact Us",
            onClick: () => showMktoForm(1067)
          }
        }
      ]
    }
  };
  const breadcrumbData = [
    {
      id: 1,
      url: "/commercial-banking",
      title: "Commercial Banking"
    },
    {
      id: 2,
      url: "/commercial-banking/specialized-industries",
      title: "Specialized Industries"
    },
    {
      id: 3,
      active: true,
      title: "Government Banking"
    }
  ];

  const featuresListData = {
    accountName: "features-list",
    items: [
      {
        id: 1,
        text: "Loans and lines of credit"
      },
      {
        id: 2,
        text: "General obligation and revenue bonds"
      },
      {
        id: 3,
        text: "Tax and revenue anticipation financing"
      },
      {
        id: 4,
        text: "Urban development and tax increment financing"
      },
      {
        id: 5,
        text: "Equipment Financing"
      }
    ]
  };

  const FeaturesContent = () => {
    return (
      <>
        <h2 className="text-success font-weight-semi-bold">Tax-exempt and taxable financing options:</h2>
        <List {...featuresListData} />
        <Button
          id={"features-cta-contact-us"}
          class={"btn-primary no-min-width w-100 w-sm-auto"}
          onClick={() => showMktoForm(1067)}
          showIcon={false}
          type={"button"}
          text={"Contact Us"}
        />
      </>
    );
  };

  const benefitsListData = {
    accountName: "deposit-treasury-list",
    items: [
      {
        id: 1,
        text: "Access to WAFD Treasury Prime to view, transact, deliver and protect your accounts"
      },
      {
        id: 2,
        text: "Products tailored to meet the needs of public entities"
      },
      {
        id: 3,
        text: "Experienced specialists to understand your specific banking needs"
      }
    ]
  };

  const BenefitsContent = () => {
    return (
      <>
        <h2 className="text-success font-weight-semi-bold">Deposit and treasury solutions:</h2>
        <List {...benefitsListData} />
        <Button
          id={"benefits-cta-contact-us"}
          class={"btn-primary no-min-width w-100 w-sm-auto"}
          onClick={() => showMktoForm(1067)}
          showIcon={false}
          type={"button"}
          text={"Contact Us"}
        />
      </>
    );
  };

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <h1>Government &amp; Institutional Banking</h1>
        <h3 className="mb-0">
          Our Government Banking team focuses exclusively on the public sector. At WaFd Bank, we bring our local and
          personal care to bear on your institution's mission, whether at the federal, state or local level. Our
          services can help you overcome challenges as our experts form relationships with you and tailor solutions
          unique to your needs.
        </h3>
      </section>
      <TwoColumnSectionWithImage isGray={true} contentOnLeft={false}>
        {{
          image: (
            <StaticImage
              src="../../../images/thumbnail-tax-exempt-taxable-financing-030724.jpg"
              alt="Businesswoman laughing on her phone with a laptop."
              placeholder="blurred"
              quality="100"
              className="border-radius-12"
            />
          ),
          content: <FeaturesContent />
        }}
      </TwoColumnSectionWithImage>
      <TwoColumnSectionWithImage>
        {{
          image: (
            <StaticImage
              src="../../../images/thumbnail-deposit-treasury-solutions-030724.jpg"
              alt="Businesswoman smiling while shaking hands with a man."
              placeholder="blurred"
              quality="100"
              className="border-radius-12"
            />
          ),
          content: <BenefitsContent />
        }}
      </TwoColumnSectionWithImage>
      <section className="container pt-0">
        <p className="text-sm text-muted mb-0">
          Subject to additional terms and conditions. Subject to credit approval. WaFd Bank does not offer tax advice.
        </p>
      </section>
      <ContactInfo type="commercial" />
      <BestBanksDefault />
    </SecondaryLanding>
  );
};
export default GovernmentBanking;
